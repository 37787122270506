import { io } from "socket.io-client";
import signalConstants from "../../api/src/chat/signalConstants";
import Vue from "vue";
let socket = null;

export default ({ store, $auth, $config }, inject) => {
  inject("chatSocket", {
    init(childId) {
      if (socket && socket.connected) {
        return;
      }

      socket = io($config.CHAT_SERVER_URL, {
        path: $config.CHAT_SOCKET_PATH,
        transports: ["websocket"],
        query: { jwtToken: $auth.strategy.token.get() },
      });

      socket.on("connect", () => {
        console.log("Socket Successfully Initialized!");

        // Join Once Init Done
        socket.emit(signalConstants.JOIN, { childId });

        this.keepAliveSocket();
        this.handleServerEvents(socket);
        this.handleSocketErrors(childId);
      });
    },

    getSocketId() {
      console.log("calling get socket id ====");
      return new Promise((resolve) => {
        const interval = setInterval(() => {
          console.log("inside interval ====");
          if (socket && socket.connected) {
            console.log("socket is connected-----", socket.id);
            clearInterval(interval);
            resolve(socket.id);
          }
        }, 200);
      });
    },

    keepAliveSocket() {
      var heartbeatInterval;

      var heartbeat = function heartbeatFn() {
        socket.emit(signalConstants.KEEP_ALIVE, {
          message: "Hello Server " + new Date().toLocaleString(),
        });
      };
      clearInterval(heartbeatInterval);
      heartbeatInterval = setInterval(heartbeat, 120000);
    },

    handleSocketErrors(childId) {
      const reInit = () => {
        socket?.disconnect();
        this.init(childId);
      };

      socket.on("disconnect", (res) => {
        console.log(res);
        reInit();
      });
      socket.on("connect_error", (err) => {
        console.log("connect_error " + err);
        reInit();
      });
      socket.on("connect_failed", (err) => {
        console.log("connect_failed " + err);
        reInit();
      });
    },

    // Server Events ------------------------------

    handleServerEvents(socket) {
      socket.on(signalConstants.FRIENDS_ONLINE, (res) => {
        store.commit("chat/setOnlineFriendsId", res.onlineFriendsId);
      });

      socket.on(signalConstants.ONLINE, (res) => {
        store.commit("chat/addOnlineUserId", res.childId);
      });

      socket.on(signalConstants.OFFLINE, (res) => {
        store.commit("chat/removeOfflineUserId", res.childId);
      });

      socket.on(signalConstants.CHATS, (res) => {
        store.dispatch("chat/setChats", res.chats);
      });

      socket.on(signalConstants.MESSAGE_RECEIVED, async (res) => {
        // console.log("MESSAGE_RECEIVED", res);
        await store.commit("chat/updateUserChatActivity", res);
        store.dispatch("chat/handleReceivedMessage", res);
      });

      socket.on(signalConstants.GROUP_CREATED, (res) => {
        store.dispatch("chat/addNewGroup", res);
      });

      socket.on(signalConstants.CHAT_CLEARED, (res) => {
        store.commit("chat/clearChat", res);
      });

      socket.on(signalConstants.CHAT_DELETED, (res) => {
        store.commit("chat/deleteChat", res);
      });

      socket.on(signalConstants.MESSAGE_DELETED, (res) => {
        store.commit("chat/deleteMessage", res);
      });

      socket.on(signalConstants.CHILD_ADDED_IN_GROUP, (res) => {
        store.commit("chat/addNewUsersInGroup", res);
      });

      socket.on(signalConstants.ADDED_IN_GROUP, (res) => {
        store.dispatch("chat/handleUserAddedInGroup", res);
      });

      socket.on(signalConstants.TYPING, (res) => {
        store.commit("chat/setTypingStatus", res);
      });

      socket.on(signalConstants.PROFILE_PIC_CHANGED, (res) => {
        store.dispatch("chat/updateNewUserProfile", res);
      });

      socket.on(signalConstants.REMOVED_FROM_GROUP, (res) => {
        store.commit("chat/removeUserFromGroup", res);
      });

      socket.on(signalConstants.CHILD_REMOVED_FROM_GROUP, (res) => {
        store.commit("chat/removeUsersFromGroup", res);
      });

      socket.on(signalConstants.GROUP_REMOVED, (res) => {
        store.commit("chat/removeUserFromGroup", res);
      });

      socket.on(signalConstants.CHAT_CACHE, (res) => {
        store.commit("chat/restoreUserChatActivities", res);
      });

      socket.on(signalConstants.CHAT_CACHE_UPDATED, (res) => {
        store.commit("chat/updateUserChatActivitiesFromOtherInstance", res);
      });

      socket.on(signalConstants.GROUP_INFO_CHANGED, (res) => {
        store.commit("chat/changeGroupInfo", res);
      });

      socket.on(signalConstants.TradeStatusChange, (res) => {
        store.commit("chat/handleTradeStatusChange", res);
      });

      socket.on(signalConstants.POP_UP_MESSAGE, (res) => {
        store.commit("chat/setPopUp", res);
      });

      socket.on(signalConstants.POINTS_UPDATED, (res) => {
        store.commit("global/UPDATE_POINTS", res.currentPoints);
      });

      socket.on(signalConstants.PAYMENT, (res) => {
        console.log("razorpay webhook received ", res);
        store.commit("general/updatePaymentStatus", res);
      });

      socket.on(signalConstants.TRADE_ACTION, (res) => {
        store.commit("global/UPDATE_TRADES", res.currentPoints);
      });

      socket.on(signalConstants.UPDATE_SENDER_TRADE_MESSAGE, (payload) => {
        store.commit("chat/changeTradeStatusInMessage", payload);
      });
    },

    // Client Events -------------------------------
    onLogout() {
      if (socket) socket.emit(signalConstants.FORCE_DISCONNECT);
      socket = null;
      store.commit("chat/setCurrentChat", null);
    },
    onSwitchChild(newChildId) {
      if (socket) socket.emit(signalConstants.SWITCH_CHILD, { newChildId });
      store.commit("chat/setCurrentChat", null);
      store.commit("chat/resetUserChatActivities", null);
    },
    sendMessage(payload) {
      if (socket) socket.emit(signalConstants.MESSAGE, payload);
    },
    sendTyping(payload) {
      if (socket) socket.emit(signalConstants.TYPING, payload);
    },
    sendDPChangeEvent(payload) {
      if (socket) socket.emit(signalConstants.CHANGE_PROFILE_PIC, payload);
    },
    sendChatCache(payload) {
      if (socket) socket.emit(signalConstants.SAVE_CHAT_CACHE, payload);
    },
    sendQuestionDoubt(payload) {
      if (socket) socket.emit(signalConstants.ASK_A_QUESTION, payload);
    },
    sendTradeUpdate(payload) {
      if (socket) socket.emit(signalConstants.TradeUpdate, payload);
    },
    refreshTradeOnChat(payload) {
      if (socket) {
        socket.emit(signalConstants.TradeStatusChange, payload);
        store.commit("chat/changeTradeStatusInMessage", payload);
        socket.emit(signalConstants.UPDATE_SENDER_TRADE_MESSAGE, {
          ...payload,
        });
      }
    },
    sendGroupNameChanged(payload) {
      if (socket) socket.emit(signalConstants.GROUP_INFO_CHANGED, payload);
    },
    ackPopUp(payload) {
      if (socket) socket.emit(signalConstants.POP_UP_MESSAGE_ACK, payload);
    },
    sendPaymentMessage(payload) {
      if (socket) socket.emit(signalConstants.PAYMENT, payload);
    },
  });
};
