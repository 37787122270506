import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _64b33622 = () => interopDefault(import('../pages/payment-confirmation.vue' /* webpackChunkName: "pages/payment-confirmation" */))
const _4e104b59 = () => interopDefault(import('../pages/profileImageUpload.vue' /* webpackChunkName: "pages/profileImageUpload" */))
const _fcf8c558 = () => interopDefault(import('../pages/resetpassword.vue' /* webpackChunkName: "pages/resetpassword" */))
const _659570ff = () => interopDefault(import('../pages/shop/index.vue' /* webpackChunkName: "pages/shop/index" */))
const _f3cd2a98 = () => interopDefault(import('../pages/subscribe.vue' /* webpackChunkName: "pages/subscribe" */))
const _c1ebdf80 = () => interopDefault(import('../pages/admin/shop-order/index.vue' /* webpackChunkName: "pages/admin/shop-order/index" */))
const _4ca96a35 = () => interopDefault(import('../pages/admin/users/index.vue' /* webpackChunkName: "pages/admin/users/index" */))
const _031fdd09 = () => interopDefault(import('../pages/callbacks/signin.vue' /* webpackChunkName: "pages/callbacks/signin" */))
const _45ed6420 = () => interopDefault(import('../pages/shop-users/orders/index.vue' /* webpackChunkName: "pages/shop-users/orders/index" */))
const _1b0d2fa8 = () => interopDefault(import('../pages/shop-users/products.vue' /* webpackChunkName: "pages/shop-users/products" */))
const _3181649c = () => interopDefault(import('../pages/shop/brands/index.vue' /* webpackChunkName: "pages/shop/brands/index" */))
const _260d1ae2 = () => interopDefault(import('../pages/shop/Brands.vue' /* webpackChunkName: "pages/shop/Brands" */))
const _25ea80c6 = () => interopDefault(import('../pages/shop/cart/index.vue' /* webpackChunkName: "pages/shop/cart/index" */))
const _33b46c82 = () => interopDefault(import('../pages/shop/categories/index.vue' /* webpackChunkName: "pages/shop/categories/index" */))
const _5cd24542 = () => interopDefault(import('../pages/shop/Categories.vue' /* webpackChunkName: "pages/shop/Categories" */))
const _0c41c428 = () => interopDefault(import('../pages/shop/checkout/index.vue' /* webpackChunkName: "pages/shop/checkout/index" */))
const _8480d1d8 = () => interopDefault(import('../pages/shop/Deals.vue' /* webpackChunkName: "pages/shop/Deals" */))
const _d0615086 = () => interopDefault(import('../pages/shop/offers/index.vue' /* webpackChunkName: "pages/shop/offers/index" */))
const _b69ebe4c = () => interopDefault(import('../pages/shop/Offers.vue' /* webpackChunkName: "pages/shop/Offers" */))
const _67ac5002 = () => interopDefault(import('../pages/shop/order-failed.vue' /* webpackChunkName: "pages/shop/order-failed" */))
const _653c17c5 = () => interopDefault(import('../pages/shop/order-history/index.vue' /* webpackChunkName: "pages/shop/order-history/index" */))
const _5b2a36b1 = () => interopDefault(import('../pages/shop/order-success.vue' /* webpackChunkName: "pages/shop/order-success" */))
const _4d67948a = () => interopDefault(import('../pages/shop/products/index.vue' /* webpackChunkName: "pages/shop/products/index" */))
const _86f60368 = () => interopDefault(import('../pages/shop/subcategories/index.vue' /* webpackChunkName: "pages/shop/subcategories/index" */))
const _3c1e167f = () => interopDefault(import('../pages/shop/tags/index.vue' /* webpackChunkName: "pages/shop/tags/index" */))
const _5f6fa602 = () => interopDefault(import('../pages/admin/shop/categories.vue' /* webpackChunkName: "pages/admin/shop/categories" */))
const _5d55bdfe = () => interopDefault(import('../pages/admin/shop/masters.vue' /* webpackChunkName: "pages/admin/shop/masters" */))
const _7d2f837d = () => interopDefault(import('../pages/admin/shop/offers/index.vue' /* webpackChunkName: "pages/admin/shop/offers/index" */))
const _2086296a = () => interopDefault(import('../pages/admin/shop/orders/index.vue' /* webpackChunkName: "pages/admin/shop/orders/index" */))
const _5b6bd04a = () => interopDefault(import('../pages/admin/shop/products/index.vue' /* webpackChunkName: "pages/admin/shop/products/index" */))
const _3cd7b17b = () => interopDefault(import('../pages/admin/shop/transactions/index.vue' /* webpackChunkName: "pages/admin/shop/transactions/index" */))
const _1bd32830 = () => interopDefault(import('../pages/shop/cart/address.vue' /* webpackChunkName: "pages/shop/cart/address" */))
const _7e440884 = () => interopDefault(import('../pages/shop/order-history/track.vue' /* webpackChunkName: "pages/shop/order-history/track" */))
const _5124b994 = () => interopDefault(import('../pages/admin/shop/products/bulk-upload.vue' /* webpackChunkName: "pages/admin/shop/products/bulk-upload" */))
const _58cf2988 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _017097e8 = () => interopDefault(import('../pages/admin/shop/offers/_id/index.vue' /* webpackChunkName: "pages/admin/shop/offers/_id/index" */))
const _68403c3c = () => interopDefault(import('../pages/admin/shop/orders/_item_id/index.vue' /* webpackChunkName: "pages/admin/shop/orders/_item_id/index" */))
const _c8209396 = () => interopDefault(import('../pages/admin/shop/products/_id/index.vue' /* webpackChunkName: "pages/admin/shop/products/_id/index" */))
const _374911b2 = () => interopDefault(import('../pages/admin/shop/transactions/_item_id/index.vue' /* webpackChunkName: "pages/admin/shop/transactions/_item_id/index" */))
const _1fa862a0 = () => interopDefault(import('../pages/admin/users/_id/index.vue' /* webpackChunkName: "pages/admin/users/_id/index" */))
const _b690ae46 = () => interopDefault(import('../pages/shop-users/orders/_item_id/index.vue' /* webpackChunkName: "pages/shop-users/orders/_item_id/index" */))
const _251380c6 = () => interopDefault(import('../pages/shop/brands/_id/index.vue' /* webpackChunkName: "pages/shop/brands/_id/index" */))
const _acc11326 = () => interopDefault(import('../pages/shop/categories/_id/index.vue' /* webpackChunkName: "pages/shop/categories/_id/index" */))
const _638c4c28 = () => interopDefault(import('../pages/shop/offers/_id/index.vue' /* webpackChunkName: "pages/shop/offers/_id/index" */))
const _34214b16 = () => interopDefault(import('../pages/shop/products/_id/index.vue' /* webpackChunkName: "pages/shop/products/_id/index" */))
const _50de1337 = () => interopDefault(import('../pages/shop/subcategories/_id/index.vue' /* webpackChunkName: "pages/shop/subcategories/_id/index" */))
const _9e0c4c2c = () => interopDefault(import('../pages/shop/tags/_id/index.vue' /* webpackChunkName: "pages/shop/tags/_id/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/payment-confirmation",
    component: _64b33622,
    name: "payment-confirmation"
  }, {
    path: "/profileImageUpload",
    component: _4e104b59,
    name: "profileImageUpload"
  }, {
    path: "/resetpassword",
    component: _fcf8c558,
    name: "resetpassword"
  }, {
    path: "/shop",
    component: _659570ff,
    name: "shop"
  }, {
    path: "/subscribe",
    component: _f3cd2a98,
    name: "subscribe"
  }, {
    path: "/admin/shop-order",
    component: _c1ebdf80,
    name: "admin-shop-order"
  }, {
    path: "/admin/users",
    component: _4ca96a35,
    name: "admin-users"
  }, {
    path: "/callbacks/signin",
    component: _031fdd09,
    name: "callbacks-signin"
  }, {
    path: "/shop-users/orders",
    component: _45ed6420,
    name: "shop-users-orders"
  }, {
    path: "/shop-users/products",
    component: _1b0d2fa8,
    name: "shop-users-products"
  }, {
    path: "/shop/brands",
    component: _3181649c,
    name: "shop-brands"
  }, {
    path: "/shop/Brands",
    component: _260d1ae2,
    name: "shop-Brands"
  }, {
    path: "/shop/cart",
    component: _25ea80c6,
    name: "shop-cart"
  }, {
    path: "/shop/categories",
    component: _33b46c82,
    name: "shop-categories"
  }, {
    path: "/shop/Categories",
    component: _5cd24542,
    name: "shop-Categories"
  }, {
    path: "/shop/checkout",
    component: _0c41c428,
    name: "shop-checkout"
  }, {
    path: "/shop/Deals",
    component: _8480d1d8,
    name: "shop-Deals"
  }, {
    path: "/shop/offers",
    component: _d0615086,
    name: "shop-offers"
  }, {
    path: "/shop/Offers",
    component: _b69ebe4c,
    name: "shop-Offers"
  }, {
    path: "/shop/order-failed",
    component: _67ac5002,
    name: "shop-order-failed"
  }, {
    path: "/shop/order-history",
    component: _653c17c5,
    name: "shop-order-history"
  }, {
    path: "/shop/order-success",
    component: _5b2a36b1,
    name: "shop-order-success"
  }, {
    path: "/shop/products",
    component: _4d67948a,
    name: "shop-products"
  }, {
    path: "/shop/subcategories",
    component: _86f60368,
    name: "shop-subcategories"
  }, {
    path: "/shop/tags",
    component: _3c1e167f,
    name: "shop-tags"
  }, {
    path: "/admin/shop/categories",
    component: _5f6fa602,
    name: "admin-shop-categories"
  }, {
    path: "/admin/shop/masters",
    component: _5d55bdfe,
    name: "admin-shop-masters"
  }, {
    path: "/admin/shop/offers",
    component: _7d2f837d,
    name: "admin-shop-offers"
  }, {
    path: "/admin/shop/orders",
    component: _2086296a,
    name: "admin-shop-orders"
  }, {
    path: "/admin/shop/products",
    component: _5b6bd04a,
    name: "admin-shop-products"
  }, {
    path: "/admin/shop/transactions",
    component: _3cd7b17b,
    name: "admin-shop-transactions"
  }, {
    path: "/shop/cart/address",
    component: _1bd32830,
    name: "shop-cart-address"
  }, {
    path: "/shop/order-history/track",
    component: _7e440884,
    name: "shop-order-history-track"
  }, {
    path: "/admin/shop/products/bulk-upload",
    component: _5124b994,
    name: "admin-shop-products-bulk-upload"
  }, {
    path: "/",
    component: _58cf2988,
    name: "index"
  }, {
    path: "/admin/shop/offers/:id",
    component: _017097e8,
    name: "admin-shop-offers-id"
  }, {
    path: "/admin/shop/orders/:item_id",
    component: _68403c3c,
    name: "admin-shop-orders-item_id"
  }, {
    path: "/admin/shop/products/:id",
    component: _c8209396,
    name: "admin-shop-products-id"
  }, {
    path: "/admin/shop/transactions/:item_id",
    component: _374911b2,
    name: "admin-shop-transactions-item_id"
  }, {
    path: "/admin/users/:id",
    component: _1fa862a0,
    name: "admin-users-id"
  }, {
    path: "/shop-users/orders/:item_id",
    component: _b690ae46,
    name: "shop-users-orders-item_id"
  }, {
    path: "/shop/brands/:id",
    component: _251380c6,
    name: "shop-brands-id"
  }, {
    path: "/shop/categories/:id",
    component: _acc11326,
    name: "shop-categories-id"
  }, {
    path: "/shop/offers/:id",
    component: _638c4c28,
    name: "shop-offers-id"
  }, {
    path: "/shop/products/:id",
    component: _34214b16,
    name: "shop-products-id"
  }, {
    path: "/shop/subcategories/:id",
    component: _50de1337,
    name: "shop-subcategories-id"
  }, {
    path: "/shop/tags/:id",
    component: _9e0c4c2c,
    name: "shop-tags-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
