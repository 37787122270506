
export default {
  props: {
    role: String,
  },
  data() {
    return {
      count: 0,
      notifications: [],
      skip: 0,
      limit: 10,
      total: 0,
      loading: false,
      fill: this.role === "admin" ? "black" : "white",
    };
  },
  mounted() {
    console.log("notifications mounted");
    this.loadNotifications();
  },
  methods: {
    redirectToOrder(order_id, order_item_id) {
      if (this.role === "admin") {
        this.$router.push("/admin/shop/orders/" + order_item_id);
      } else {
        this.$router.push(
          `/shop/order-history/track?order_id=${order_id}&item_id=${order_item_id}`
        );
      }
    },
    async markSeen() {
      const response = await this.$api.notifications.patch(0, {
        user_type: this.role,
      });
      this.count = 0;
    },
    async loadNotifications() {
      if (this.loading) return;
      this.loading = true;

      const { results: response, count } = await this.$api.notifications.find({
        $skip: this.skip,
        $limit: this.limit,
        user_type: this.role,
        "$sort[id]": -1,
      });
      this.count = count;

      this.notifications = [
        ...this.notifications,
        ...response.data.map((item) => ({
          ...item,
          extraAttr: JSON.parse(item.extraAttr),
        })),
      ];
      this.total = response.total;
      this.skip += this.limit;
      this.loading = false;
    },
    onScroll() {
      this.$refs.scrollContainer.$el.id = "scroll-container";

      const scrollContainer = document.getElementById("scroll-container");

      const bottomOfScroll =
        Math.floor(scrollContainer.scrollHeight - scrollContainer.scrollTop) ===
          scrollContainer.clientHeight ||
        Math.ceil(scrollContainer.scrollHeight - scrollContainer.scrollTop) ===
          scrollContainer.clientHeight;

      if (bottomOfScroll && this.notifications.length <= this.total) {
        this.loadNotifications();
      }
    },
  },
};
