const middleware = {}

middleware['admin-only'] = require('../middleware/admin-only.js')
middleware['admin-only'] = middleware['admin-only'].default || middleware['admin-only']

middleware['coupon-user-only'] = require('../middleware/coupon-user-only.js')
middleware['coupon-user-only'] = middleware['coupon-user-only'].default || middleware['coupon-user-only']

middleware['deepLink'] = require('../middleware/deepLink.js')
middleware['deepLink'] = middleware['deepLink'].default || middleware['deepLink']

middleware['school-redirect'] = require('../middleware/school-redirect.js')
middleware['school-redirect'] = middleware['school-redirect'].default || middleware['school-redirect']

middleware['shop-user-only'] = require('../middleware/shop-user-only.js')
middleware['shop-user-only'] = middleware['shop-user-only'].default || middleware['shop-user-only']

middleware['under-maintenance'] = require('../middleware/under-maintenance.js')
middleware['under-maintenance'] = middleware['under-maintenance'].default || middleware['under-maintenance']

export default middleware
