export const AskConfirmation = () => import('../../components/AskConfirmation.vue' /* webpackChunkName: "components/ask-confirmation" */).then(c => wrapFunctional(c.default || c))
export const Breadcrumb = () => import('../../components/Breadcrumb.vue' /* webpackChunkName: "components/breadcrumb" */).then(c => wrapFunctional(c.default || c))
export const CountryFlag = () => import('../../components/CountryFlag.vue' /* webpackChunkName: "components/country-flag" */).then(c => wrapFunctional(c.default || c))
export const CouponUsageUser = () => import('../../components/CouponUsageUser.vue' /* webpackChunkName: "components/coupon-usage-user" */).then(c => wrapFunctional(c.default || c))
export const CustomPagination = () => import('../../components/CustomPagination.vue' /* webpackChunkName: "components/custom-pagination" */).then(c => wrapFunctional(c.default || c))
export const DateTimer = () => import('../../components/DateTimer.vue' /* webpackChunkName: "components/date-timer" */).then(c => wrapFunctional(c.default || c))
export const HyperlinkPagination = () => import('../../components/HyperlinkPagination.vue' /* webpackChunkName: "components/hyperlink-pagination" */).then(c => wrapFunctional(c.default || c))
export const ImageDropBox = () => import('../../components/ImageDropBox.vue' /* webpackChunkName: "components/image-drop-box" */).then(c => wrapFunctional(c.default || c))
export const ImagePicker = () => import('../../components/ImagePicker.vue' /* webpackChunkName: "components/image-picker" */).then(c => wrapFunctional(c.default || c))
export const Notifications = () => import('../../components/Notifications.vue' /* webpackChunkName: "components/notifications" */).then(c => wrapFunctional(c.default || c))
export const ProfileFrame = () => import('../../components/ProfileFrame.vue' /* webpackChunkName: "components/profile-frame" */).then(c => wrapFunctional(c.default || c))
export const ProgressBar = () => import('../../components/ProgressBar.vue' /* webpackChunkName: "components/progress-bar" */).then(c => wrapFunctional(c.default || c))
export const ProgressBarModal = () => import('../../components/ProgressBarModal.vue' /* webpackChunkName: "components/progress-bar-modal" */).then(c => wrapFunctional(c.default || c))
export const RoleSwitcher = () => import('../../components/RoleSwitcher.vue' /* webpackChunkName: "components/role-switcher" */).then(c => wrapFunctional(c.default || c))
export const SignIn = () => import('../../components/SignIn.vue' /* webpackChunkName: "components/sign-in" */).then(c => wrapFunctional(c.default || c))
export const SignUp = () => import('../../components/SignUp.vue' /* webpackChunkName: "components/sign-up" */).then(c => wrapFunctional(c.default || c))
export const Snackbar = () => import('../../components/Snackbar.vue' /* webpackChunkName: "components/snackbar" */).then(c => wrapFunctional(c.default || c))
export const ConfirmDialog = () => import('../../components/confirmDialog.vue' /* webpackChunkName: "components/confirm-dialog" */).then(c => wrapFunctional(c.default || c))
export const ConfirmDialogNew = () => import('../../components/confirmDialogNew.vue' /* webpackChunkName: "components/confirm-dialog-new" */).then(c => wrapFunctional(c.default || c))
export const CreateAccountNew1 = () => import('../../components/createAccountNew1.vue' /* webpackChunkName: "components/create-account-new1" */).then(c => wrapFunctional(c.default || c))
export const Filters = () => import('../../components/filters.vue' /* webpackChunkName: "components/filters" */).then(c => wrapFunctional(c.default || c))
export const FullScreenLoader = () => import('../../components/fullScreenLoader.vue' /* webpackChunkName: "components/full-screen-loader" */).then(c => wrapFunctional(c.default || c))
export const Loader = () => import('../../components/loader.vue' /* webpackChunkName: "components/loader" */).then(c => wrapFunctional(c.default || c))
export const Pagination = () => import('../../components/pagination.vue' /* webpackChunkName: "components/pagination" */).then(c => wrapFunctional(c.default || c))
export const ProfileBanner = () => import('../../components/profileBanner.vue' /* webpackChunkName: "components/profile-banner" */).then(c => wrapFunctional(c.default || c))
export const UserUpdateDetails = () => import('../../components/userUpdateDetails.vue' /* webpackChunkName: "components/user-update-details" */).then(c => wrapFunctional(c.default || c))
export const MiscDiscountCodeCountDown = () => import('../../components/Misc/DiscountCodeCountDown.vue' /* webpackChunkName: "components/misc-discount-code-count-down" */).then(c => wrapFunctional(c.default || c))
export const MiscLeadershipTable = () => import('../../components/Misc/LeadershipTable.vue' /* webpackChunkName: "components/misc-leadership-table" */).then(c => wrapFunctional(c.default || c))
export const ShopBreadcrumbs = () => import('../../components/Shop/Breadcrumbs.vue' /* webpackChunkName: "components/shop-breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const ShopCategoriesComponent = () => import('../../components/Shop/CategoriesComponent.vue' /* webpackChunkName: "components/shop-categories-component" */).then(c => wrapFunctional(c.default || c))
export const ShopProductView = () => import('../../components/Shop/ProductView.vue' /* webpackChunkName: "components/shop-product-view" */).then(c => wrapFunctional(c.default || c))
export const ShopHomeMetaTags = () => import('../../components/Shop/ShopHomeMetaTags.vue' /* webpackChunkName: "components/shop-home-meta-tags" */).then(c => wrapFunctional(c.default || c))
export const PopupMessage = () => import('../../components/popup-dialog/PopupMessage.vue' /* webpackChunkName: "components/popup-message" */).then(c => wrapFunctional(c.default || c))
export const ShopBreadCrumbs = () => import('../../components/Shop/BreadCrumbs/index.vue' /* webpackChunkName: "components/shop-bread-crumbs" */).then(c => wrapFunctional(c.default || c))
export const ShopCheckoutAddress = () => import('../../components/Shop/Checkout/Address.vue' /* webpackChunkName: "components/shop-checkout-address" */).then(c => wrapFunctional(c.default || c))
export const ShopCheckoutPayment = () => import('../../components/Shop/Checkout/Payment.vue' /* webpackChunkName: "components/shop-checkout-payment" */).then(c => wrapFunctional(c.default || c))
export const ShopCheckoutCart = () => import('../../components/Shop/Checkout/cart.vue' /* webpackChunkName: "components/shop-checkout-cart" */).then(c => wrapFunctional(c.default || c))
export const ShopMastersBrands = () => import('../../components/Shop/Masters/Brands.vue' /* webpackChunkName: "components/shop-masters-brands" */).then(c => wrapFunctional(c.default || c))
export const ShopMastersCategories = () => import('../../components/Shop/Masters/Categories.vue' /* webpackChunkName: "components/shop-masters-categories" */).then(c => wrapFunctional(c.default || c))
export const ShopOffersDetails = () => import('../../components/Shop/Offers/Details.vue' /* webpackChunkName: "components/shop-offers-details" */).then(c => wrapFunctional(c.default || c))
export const ShopOffersProductSelector = () => import('../../components/Shop/Offers/ProductSelector.vue' /* webpackChunkName: "components/shop-offers-product-selector" */).then(c => wrapFunctional(c.default || c))
export const ShopOffersQuestionSetSelector = () => import('../../components/Shop/Offers/QuestionSetSelector.vue' /* webpackChunkName: "components/shop-offers-question-set-selector" */).then(c => wrapFunctional(c.default || c))
export const ShopOrdersOrderDetails = () => import('../../components/Shop/Orders/OrderDetails.vue' /* webpackChunkName: "components/shop-orders-order-details" */).then(c => wrapFunctional(c.default || c))
export const ShopOrdersOrderList = () => import('../../components/Shop/Orders/OrderList.vue' /* webpackChunkName: "components/shop-orders-order-list" */).then(c => wrapFunctional(c.default || c))
export const ShopProductsDetails = () => import('../../components/Shop/Products/Details.vue' /* webpackChunkName: "components/shop-products-details" */).then(c => wrapFunctional(c.default || c))
export const ShopProductsFilter = () => import('../../components/Shop/Products/Filter.vue' /* webpackChunkName: "components/shop-products-filter" */).then(c => wrapFunctional(c.default || c))
export const ShopProductsProductList = () => import('../../components/Shop/Products/ProductList.vue' /* webpackChunkName: "components/shop-products-product-list" */).then(c => wrapFunctional(c.default || c))
export const ShopProductsManager = () => import('../../components/Shop/Products/ProductsManager.vue' /* webpackChunkName: "components/shop-products-manager" */).then(c => wrapFunctional(c.default || c))
export const ShopProductsUsers = () => import('../../components/Shop/Products/Users.vue' /* webpackChunkName: "components/shop-products-users" */).then(c => wrapFunctional(c.default || c))
export const ShopTransactionsOrderDetails = () => import('../../components/Shop/Transactions/OrderDetails.vue' /* webpackChunkName: "components/shop-transactions-order-details" */).then(c => wrapFunctional(c.default || c))
export const ShopTransactionsTransactionList = () => import('../../components/Shop/Transactions/TransactionList.vue' /* webpackChunkName: "components/shop-transactions-transaction-list" */).then(c => wrapFunctional(c.default || c))
export const ShopProductsVariationsImages = () => import('../../components/Shop/Products/Variations/Images.vue' /* webpackChunkName: "components/shop-products-variations-images" */).then(c => wrapFunctional(c.default || c))
export const ShopProductsVariationsProductForm = () => import('../../components/Shop/Products/Variations/ProductForm.vue' /* webpackChunkName: "components/shop-products-variations-product-form" */).then(c => wrapFunctional(c.default || c))
export const ShopProductsVariationsEmailTemplate = () => import('../../components/Shop/Products/Variations/emailTemplate.vue' /* webpackChunkName: "components/shop-products-variations-email-template" */).then(c => wrapFunctional(c.default || c))
export const ShopProductsVariations = () => import('../../components/Shop/Products/Variations/index.vue' /* webpackChunkName: "components/shop-products-variations" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
