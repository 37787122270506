import { mapGetters, mapActions, mapMutations } from "vuex";
import { SUBJECT_NAME } from "@/lib/constants";

export default {
  data() {
    return {
      dataClientID: "ca-pub-5736152236128320",
      horizontalDataSlotID: "5294506972",
      leftVerticalDataSlot: "5658496725",
      rightVerticalDataSlot: "6607588645",
      leftMobViewAddSlot: "8495385387",
      rightMobViewAddSlot: "4364568689",
    };
  },
  computed: {
    ...mapGetters("global", ["getChild", "getAvatar"]),
    ...mapGetters("general", ["getSubjects"]),
    ...mapGetters("childProfile", ["getSubjectLevels"]),
    getFirstName() {
      let parent_name = this.$auth.user.parent_name.split(" ");
      return parent_name[0];
    },
    getLastName() {
      let parent_name = this.$auth.user.parent_name.split(" ");
      let lastName = parent_name[1];
      return lastName ? lastName : "";
    },
    getChilds() {
      return this.$auth?.user?.user_profile;
    },
    getInitials() {
      return this.getChild.name ? this.getChild.name.charAt(0) : "A";
    },
    totalChilds() {
      return this.getChilds ? this.getChilds.length : 0;
    },
  },
  methods: {
    async addChildPoints(points, opts = {}) {
      let user = JSON.parse(JSON.stringify(this.$auth.user));
      await this.$auth.setUser({ ...user, points: user.points + points });
    },
    async removeChildPoints(points) {
      let user = JSON.parse(JSON.stringify(this.$auth.user));
      console.log("user =============  ", user, "===== points ==== ", points);
      await this.$auth.setUser({ ...user, points: user.points - points });
    },
    reduceJumpTestCounter() {
      const child = { ...this.getChild };
      child.jumpTestCounter = child.jumpTestCounter - 1;
      this.updateChild(child);
      this.setChild(child);
    },
    async updateChild(child) {
      let user = JSON.parse(JSON.stringify(this.$auth.user));
      await this.$auth.setUser(user);
    },
    async safeSetChild(child) {
      if (child.id === this.getChild.id) {
        const dt = new Date().toLocaleDateString().toString();
        if (
          this.$auth.user.user_profile &&
          dt !== this.getChild.profileUpdatedAt
        ) {
          child = this.$auth.user.user_profile.find(
            (user) => user.id === child.id
          );
          if (!child) {
            child = this.$auth.user.user_profile[0];
          }
        }
      }

      await this.setChild(child);
    },
    async getUserProperties() {
      return await this.$api.users.find({
        controller: "user-properties",
        userId: this.$auth.user.id,
      });
    },
    calculateCartTotalDeduction(cart) {
      const payment_amount = cart.reduce(
        (acc, c) => (acc += c.payment_amount),
        0
      );
      const points_used = 0;
      const original_price = cart.reduce((acc, c) => acc + c.net_price, 0);

      return {
        payment_amount,
        points_used,
        original_price,
      };
    },
    createCartCheckoutPayload(cart) {
      return {
        items: cart.map((c) => {
          return {
            product_id: c.product_id,
            product_variation_id: c.variant_id,
            qty: c.qty,
            price: c.price,
            points: c.points,
            payment_amount: c.payment_amount,
            points_used: c.points_used,
            variant: c.variant,
            buyNow: c.buyNow ? true : false,
          };
        }),
        ...this.calculateCartTotalDeduction(cart),
      };
    },
    ...mapActions("global", ["setChild"]),
    ...mapMutations("general", ["setSubjects"]),
    ...mapMutations("global", ["SET_AVATAR", "SET_SUBSCRIPTION"]),
  },
};
