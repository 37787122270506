
import { mapMutations } from "vuex";

export default {
  middleware: ["under-maintenance"],
  scrollToTop: true,
  components: {},
  head: {
    link: [{ rel: "icon", type: "image/x-icon", href: "/favicon.ico" }],
  },
  data() {
    return {
      isSinginSingupModel: false,
      selectedPath: null,
      isSidebarOpen: false,
      hideFooter: false,
    };
  },
  methods: {
    ...mapMutations("global", ["setShopCurrency"]),
    onSelectHandler(event) {
      if (this.selectedPath) {
        this.selectedPath = event.name;
      }

      if (!this.$auth.loggedIn && !event.noLoginRequired) {
        this.isSinginSingupModel = true;
      } else {
        this.isSinginSingupModel = false;
        this.$router.push(event.url);
      }
    },
    closeSignInSignUp() {
      this.isSinginSingupModel = false;
    },
    toggleSide() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },
  },
  async created() {
    const shopCountries = await this.$api.shop.find({
      items: "shopCountries",
    });
    const currency = shopCountries.find((el) => el.id === 81);
    this.setShopCurrency(currency);
  },
  mounted() {
    this.hideFooter = this.$route.query.hideFooter;
  },
};
