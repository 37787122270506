import Vue from "vue";

export const state = () => ({
  appProgressBar: false,
  selectedUserProfile: null,
  subjects: [],
  pageNavigationCache: null,
  offer: null,
  brand: null,
  category: null,
  childProfileHash: null,
  paymentStatus: [],
});

export const actions = {
  async setChildProfileHash({ state, commit, rootState }, payload) {
    const { selectedUserProfile: currentChild } = rootState.global;

    commit("setChildProfileHash", {
      childId: currentChild?.id ?? 0,
    });
  },
};

export const mutations = {
  appProgressBar(state, status) {
    state.appProgressBar = status;
  },
  setSubjects(state, payload) {
    state.subjects = payload;
  },
  setPageNavigationCache(state, payload) {
    state.pageNavigationCache = payload;
  },
  setOffer(state, payload) {
    state.offer = payload;
  },
  setBrand(state, payload) {
    state.brand = payload;
  },
  setCategory(state, payload) {
    state.category = payload;
  },
  setChildProfileHash(state, payload) {
    state.childProfileHash = payload;
  },
  addPaymentStatus(state, payload) {
    state.paymentStatus.push(payload);
  },
  updatePaymentStatus(state, payload) {
    const orderDetailsIndex = state.paymentStatus.findIndex(
      (el) => el.orderId == payload.orderId
    );
    if (orderDetailsIndex !== -1)
      Vue.set(state.paymentStatus, orderDetailsIndex, payload);
  },
  deletePaymentStatus(state, payload) {
    const orderDetailsIndex = state.paymentStatus.findIndex(
      (el) => el.orderId == payload.orderId
    );
    state.paymentStatus.splice(orderDetailsIndex, 1);
  },
};

export const getters = {
  getAppProgressbarStatus: (state) => state.appProgressBar,
  getSubjects: (state) => state.subjects,
  getOffer: (state) => state.offer,
  getBrand: (state) => state.brand,
  getCategory: (state) => state.category,
};
