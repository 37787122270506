import { render, staticRenderFns } from "./parents.vue?vue&type=template&id=466dae46&scoped=true"
import script from "./parents.vue?vue&type=script&lang=js"
export * from "./parents.vue?vue&type=script&lang=js"
import style0 from "./parents.vue?vue&type=style&index=0&id=466dae46&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "466dae46",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Notifications: require('/home/app/gmmco/client/components/Notifications.vue').default,RoleSwitcher: require('/home/app/gmmco/client/components/RoleSwitcher.vue').default,ProgressBar: require('/home/app/gmmco/client/components/ProgressBar.vue').default,PopupMessage: require('/home/app/gmmco/client/components/popup-dialog/PopupMessage.vue').default,Snackbar: require('/home/app/gmmco/client/components/Snackbar.vue').default})
