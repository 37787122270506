import moment from "moment-timezone";

export function convertLeaderboardPoints(value) {
  if (value >= 1000000000) {
    return (value / 1000000000).toFixed(3).replace(/\.0$/, "") + "B";
  }
  if (value >= 1000000) {
    return (value / 1000000).toFixed(3).replace(/\.0$/, "") + "M";
  }

  if (value >= 100000) {
    return (value / 1000).toFixed(3).replace(/\.0$/, "") + "K";
  }

  return value?.toFixed(2);
}

export function convertServerTimeToIST(dateTime) {
  const indianTimeZone = "Asia/Calcutta";
  const dateFormat = "YYYY-MM-DD HH:mm";

  return moment
    .tz(dateTime, dateFormat, process.env.SERVER_TIMEZONE)
    .tz(indianTimeZone)
    .format(dateFormat);
}

export function generateRandomString(length = 8) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
