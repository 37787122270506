import { mapGetters, mapState, mapMutations } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapState("global", ["unregisteredUserId"]),
    ...mapGetters("global", ["getChild"]),
    registeredUser() {
      return this.$auth.loggedIn;
    },
    childId() {
      return this?.getChild?.id;
    },
  },
  methods: {
    ...mapMutations("cart", ["setCartItemsCount"]),
    async getCart() {
      const resp = await this.$api.cart.find({
        country_id: this.$auth?.user?.shopCountry?.id ?? 81,
      });

      const items = resp?.items ?? [];

      this.setCartItemsCount(items?.length);

      return items;
    },
    async addItemToCart(item) {
      const requestData = {
        controller: "addItem",
        ...item,
      };
      const res = await this.$api.cart.create(requestData);
      this.getCart();
      return res;
    },
    async removeItemFromCart(item) {
      const requestData = {
        controller: "removeItem",
        ...item,
      };
      await this.$api.cart.create(requestData);
    },
    async subtractItemQuantity(item) {
      const requestData = {
        controller: "subtractQuantity",
        ...item,
      };
      await this.$api.cart.create(requestData);
    },
    async addItemQuantity(item) {
      const requestData = {
        controller: "addQuantity",
        ...item,
      };
      await this.$api.cart.create(requestData);
    },
  },
};
