const SERVICE_NAME = "admin/inventory/bulk-upload";
import backend from "@/lib/backend";
export default (axios) => ({
  create(data) {
    return backend(axios, SERVICE_NAME).create(data);
  },

  verifyUpload(data) {
    return backend(axios, `${SERVICE_NAME}/verify`).create(data);
  },
});
