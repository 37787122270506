const SERVICE_NAME = "product-sub-categories";
import backend from "@/lib/backend";
export default (axios) => ({
  find(data) {
    return backend(axios, SERVICE_NAME).find(data);
  },
  create(data) {
    return backend(axios, SERVICE_NAME).create(data);
  },
  patch(id, data) {
    return backend(axios, SERVICE_NAME).patch(id, data);
  },
});
