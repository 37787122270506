
import {
  mdiAccountGroup,
  mdiShopping,
  mdiArchive,
  mdiExitToApp,
  mdiChevronDown,
} from "@mdi/js";
import RoleSwitcher from "@/components/RoleSwitcher.vue";
import Notifications from "@/components/Notifications.vue";
export default {
  middleware: ["auth", "admin-only", "under-maintenance"],
  components: [RoleSwitcher, Notifications],
  data: () => ({
    dialog: false,
    closable: true,
    selectedItem: 0,
    items: [
      {
        text: "Users",
        icon: mdiAccountGroup,
        items: [
          {
            text: "Users Details",
            url: "/admin/users",
          },
        ],
      },
      {
        text: "Shop",
        icon: mdiShopping,
        items: [
          {
            text: "Categories",
            url: "/admin/shop/categories",
          },
          {
            text: "Products",
            url: "/admin/shop/products",
          },
          {
            text: "Orders",
            url: "/admin/shop/orders",
          },
          {
            text: "Transactions",
            url: "/admin/shop/transactions",
          },
          {
            text: "Banner",
            url: "/admin/shop/offers",
          },
        ],
      },
      // {
      //   text: "Config",
      //   icon: mdiArchive,
      //   items: [
      //     {
      //       text: "Shop Order",
      //       url: "/admin/shop-order",
      //     },
      //   ],
      // },
    ],
    icons: {
      mdiExitToApp,
      mdiChevronDown,
    },
    drawer: true,
  }),
  computed: {
    userName() {
      if (this.$auth && this.$auth.user) {
        const name = this.$auth.user.parent_name;

        return name
          .split(" ")
          .map((l) => l.charAt(0))
          .join("");
      }
      return "";
    },
  },
  mounted() {
    console.log(this.$route);

    if (!this.$auth.user.email_verified) {
      // this.$router.replace("/varify-email");
      this.dialog = true;

      let currDate = new Date();
      let userCreationDate = new Date(this.$auth.user.createdAt);

      const timeDiff = Math.abs(
        userCreationDate.getTime() - currDate.getTime()
      );
      const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

      if (diffDays > 7) {
        this.closable = false;
      }
    }
  },
  methods: {
    resendLink() {
      this.$api.notify
        .sendVarificationMail({ email: this.$auth.user.email })
        .then((rsp) => {
          this.snackbarText = rsp.message;
          this.snackbar = true;
          this.dialog = false;
          // alert(rsp.message)
        });
    },
    logout() {
      this.$auth.logout();
      this.$router.replace("/");
    },
  },
};
