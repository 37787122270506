import Users from "@/api/users.js";
import Notify from "@/api/notify.js";
import Questions from "@/api/questions.js";
import AgeLevels from "@/api/age-levels.js";
import Subscription from "@/api/subscription.js";
import StudentPlanSubscriptions from "@/api/student-plan-subscriptions.js";
import Countries from "@/api/countries.js";
import CountryRates from "@/api/country-rates.js";
import ForgotPassword from "@/api/forgot-password.js";
import UserProfile from "@/api/userProfile";
import Subjects from "@/api/subjects";
import Uploads from "@/api/uploads";
import QuestionSets from "../api/question-sets";
import QuestionTypes from "../api/question-types";
import Institutes from "../api/institutes";
import PracticeQuestionSet from "../api/practice-questionset";
import Leaderboard from "../api/leaderboard";
import UserSubjectLevel from "../api/userSubjectLevel";
import PaymentTransactions from "../api/payment-transactions";
import GiftSubscriptionValidation from "../api/validate-gift-subscription";
import GiftSubscription from "../api/gift-subscription";
import ClaimGift from "../api/claim-gift";
import ChildSubscription from "../api/child-subscriptions";
import ChildHistory from "../api/child-history";
import Order from "../api/order";
import Offers from "../api/offers";
import OfferDetails from "../api/offer-details";
import PlanRates from "../api/plan-rates";
import Plans from "../api/plans";
import UserRequest from "../api/user-request";
import DailyQuestions from "../api/daily-questions";
import Badges from "../api/badges";
import NewsletterSubscribers from "../api/newsletter-subscribers";
import Assessment from "../api/assessment";
import JumpTest from "../api/jump-test";
import Referral from "../api/referral";
import AskDoubt from "../api/ask-doubt";
import FriendRequests from "../api/friend-requests";
import QuestionsetHistory from "../api/questionset-history";
import sendExcel from "../api/send-excel";
import DailyQuestionsAdditionalPoints from "@/api/daily-questions-additional-points";
import ProductBrands from "../api/product-brands";
import ProductCategories from "../api/product-categories";
import ProductSubCategories from "../api/product-sub-categories";
import Products from "../api/products";
import ImageUpload from "../api/image_upload";
import ShopOffers from "../api/shop-offers";
import ShopSearchTags from "../api/shop_search_tags";
import Shop from "../api/shop";
import UsersAddress from "../api/users-address";
import ShopOrder from "../api/shop-order";
import ClubOrderItems from "@/api/club-order-items";
import ProductVariations from "@/api/product-variations";
import DigitalStore from "@/api/digital-store";
import ClubChildInventory from "@/api/club-child-inventory";
import Config from "@/api/config";
import ChildMonthlyPoints from "@/api/child-monthly-points";
import ChildPointsHistory from "@/api/child-points-history";
import ChildSubjectLevelAccuracy from "@/api/child-subject-level-history";

import LeaderboardReferal from "@/api/leaderboard-referal";
import DailyQuestionPerformance from "@/api/daily-question-performance";
import Chat from "@/api/chat";
import ChildActiveHistory from "@/api/child-active-history";
import Competitions from "@/api/competitions";
import ChildCompetitions from "@/api/child-competition";
import WildCard from "@/api/wildcard";
import Stories from "@/api/stories";
import StoryCategories from "@/api/story-categories";
import Cart from "@/api/cart";
import ChildTrade from "@/api/childTrade";
import DevUtility from "@/api/dev-utility";

import ChildStoriesPerformance from "@/api/child-stories-performance";
import Challenges from "@/api/challenges";
import Advertisement from "@/api/advertisement";
import Topics from "@/api/topics";
import Lessons from "@/api/lessons";
import GenericService from "@/api/generic-service";
import GeoLocation from "@/api/ipgeolocation";

import InventoryBulkUpload from "../api/inventory-bulk-upload";
import Transactions from "../api/transactions";
import Notifications from "../api/notification";

export default (context, inject) => {
  //Intialize API factories
  const factories = {
    users: Users(context.$axios),
    notify: Notify(context.$axios),
    institutes: Institutes(context.$axios),
    questions: Questions(context.$axios),
    ageLevels: AgeLevels(context.$axios),
    //  userSubscriptions: UserSubscription(context.$axios),
    subscription: Subscription(context.$axios),
    studentPlanSubscriptions: StudentPlanSubscriptions(context.$axios),
    countries: Countries(context.$axios),
    countryRates: CountryRates(context.$axios),
    plans: Plans(context.$axios),
    planRates: PlanRates(context.$axios),
    forgotPassword: ForgotPassword(context.$axios),
    userProfile: UserProfile(context.$axios),
    subjects: Subjects(context.$axios),
    uploads: Uploads(context.$axios),
    questionSets: QuestionSets(context.$axios),
    questionTypes: QuestionTypes(context.$axios),
    practiceQuestionSet: PracticeQuestionSet(context.$axios),
    leaderboard: Leaderboard(context.$axios),
    userSubjectLevel: UserSubjectLevel(context.$axios),
    giftSubscription: GiftSubscription(context.$axios),
    giftSubscriptionValidation: GiftSubscriptionValidation(context.$axios),
    paymentTransactions: PaymentTransactions(context.$axios),
    claimGift: ClaimGift(context.$axios),
    childSubscription: ChildSubscription(context.$axios),
    childHistory: ChildHistory(context.$axios),
    dailyQuestions: DailyQuestions(context.$axios),
    order: Order(context.$axios),
    offers: Offers(context.$axios),
    offerDetails: OfferDetails(context.$axios),
    userRequest: UserRequest(context.$axios),
    badges: Badges(context.$axios),
    newsletterSubscribers: NewsletterSubscribers(context.$axios),
    assessment: Assessment(context.$axios),
    jumpTest: JumpTest(context.$axios),
    referral: Referral(context.$axios),
    askDoubt: AskDoubt(context.$axios),
    friendRequests: FriendRequests(context.$axios),
    questionSetHistory: QuestionsetHistory(context.$axios),
    sendExcel: sendExcel(context.$axios),
    dailyQuestionsAdditionalPoints: DailyQuestionsAdditionalPoints(
      context.$axios
    ),
    productBrands: ProductBrands(context.$axios),
    productCategories: ProductCategories(context.$axios),
    productSubCategories: ProductSubCategories(context.$axios),
    products: Products(context.$axios),
    imageUpload: ImageUpload(context.$axios),
    shopOffers: ShopOffers(context.$axios),
    shopSearchTags: ShopSearchTags(context.$axios),
    shop: Shop(context.$axios),
    usersAddress: UsersAddress(context.$axios),
    shopOrder: ShopOrder(context.$axios),
    clubOrderItems: ClubOrderItems(context.$axios),
    productVariations: ProductVariations(context.$axios),
    digitalStore: DigitalStore(context.$axios),
    clubChildInventory: ClubChildInventory(context.$axios),
    config: Config(context.$axios),
    childMonthlyPoints: ChildMonthlyPoints(context.$axios),
    leaderboardReferal: LeaderboardReferal(context.$axios),
    childPointsHistory: ChildPointsHistory(context.$axios),
    childSubjectLevelAccuracy: ChildSubjectLevelAccuracy(context.$axios),
    dailyQuestionPerformance: DailyQuestionPerformance(context.$axios),
    chat: Chat(context.$axios),
    childActiveHistory: ChildActiveHistory(context.$axios),
    competitions: Competitions(context.$axios),
    childCompetitions: ChildCompetitions(context.$axios),
    wildcard: WildCard(context.$axios),
    stories: Stories(context.$axios),
    storyCategories: StoryCategories(context.$axios),
    cart: Cart(context.$axios),
    childTrade: ChildTrade(context.$axios),
    devUtility: DevUtility(context.$axios),
    childStoriesPerformance: ChildStoriesPerformance(context.$axios),
    challenges: Challenges(context.$axios),
    advertisement: Advertisement(context.$axios),
    topics: Topics(context.$axios),
    lessons: Lessons(context.$axios),
    genericService: GenericService(context.$axios),
    geoLocationService: GeoLocation(context.$axios),
    inventoryBulkUpload: InventoryBulkUpload(context.$axios),
    transactions: Transactions(context.$axios),
    notifications: Notifications(context.$axios),
  };

  inject("api", factories);
};
