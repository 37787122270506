
import { convertLeaderboardPoints } from "@/lib/common";
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";
import ProgressBar from "../components/ProgressBar.vue";
import user_mixin from "~/mixins/user_mixin";
import PopupMessage from "@/components/popup-dialog/PopupMessage";
import {
  mdiMenu,
  mdiSegment,
  mdiCartOutline,
  mdiForumOutline,
  mdiStar,
  mdiChevronDown,
  mdiAccountConvertOutline,
  mdiAccountOutline,
  mdiCloseBox,
  mdiMagnify,
  mdiAlarmBell,
  mdiAccountGroup,
  mdiShopping,
  mdiArchive,
} from "@mdi/js";
import moment from "moment-timezone";
import RoleSwitcher from "@/components/RoleSwitcher.vue";
import Notifications from "@/components/Notifications.vue";
import shop_mixin from "@/mixins/shop_mixin";

export default {
  name: "parents",
  mixins: [user_mixin, shop_mixin],
  middleware: ["deepLink", "school-redirect", "auth", "under-maintenance"],
  components: {
    ProgressBar,
    PopupMessage,
    RoleSwitcher,
    Notifications,
  },
  watch: {
    isSidebarOpen(v) {
      this.drawer = !v;
    },
    drawer(v) {
      // if (!v) this.isSidebarOpen = true;
    },
    getChild: function () {
      this.imageURL = this.getAvatar;
    },
    $route(to, from) {
      const previousRouteIncludesOpponent = from.fullPath.includes("/opponent");
      const newRouteIncludesOpponent = to.fullPath.includes("/opponent");

      if (previousRouteIncludesOpponent && !newRouteIncludesOpponent) {
        this.$multiplayerSocket.leftGame();
      }
    },
  },
  data: () => ({
    items: [],
    navItems: [],
    drawer: false,
    isSidebarOpen: true,
    isSuccess: false,
    isFirstLogin: false,
    selectedItem: null,
    dialog: false,
    closable: true,
    jumpRequest: false,
    childProfileDialog: false,
    isMobile: false,
    imageURL: "",

    icons: {
      mdiMenu,
      mdiSegment,
      mdiForumOutline,
      mdiStar,
      mdiChevronDown,
      mdiAccountConvertOutline,
      mdiAccountOutline,
      mdiCloseBox,
      mdiCartOutline,
      mdiMagnify,
      mdiAlarmBell,
    },
    supportedCountries: [91],
    country_code: "IN",
    mini: false,
    showMyOrdersButton: true,
    cart: [],
    searchText: "",
  }),

  async mounted() {
    this.fetchCategories();

    document
      .getElementById("product-search-field-layout")
      .addEventListener("input", debounce(this.searchProducts));

    // const shopIndex = this.items.findIndex(el => el.text === "Shop");
    // const supportedCountry = this.supportedCountries.includes(
    //   this.$auth.user.country_code
    // );
    // if (!supportedCountry) this.items.splice(shopIndex, 1);

    // Connect and Join with Chat Socket
    if (this.selectedUserProfile) {
      this.$chatSocket.init(this.selectedUserProfile.id);
      // this.$multiplayerSocket.init(this.selectedUserProfile.id);
    }

    try {
      this.isMobile = window.innerWidth < 600;
      this.safeSetChild(this.getChild);

      this.$api.countries
        .find({
          id: this.$auth.user.country_id,
        })
        .then((data) => {
          this.country_code = data.data[0].country_code;
        });
      if (this.$route.params.newUser) {
        this.isSuccess = true;
        this.isFirstLogin = true;
      }
      if (!this.$auth.user.email_verified) {
        this.dialog = true;

        let currDate = new Date();
        let userCreationDate = new Date(this.$auth.user.createdAt);

        const timeDiff = Math.abs(
          userCreationDate.getTime() - currDate.getTime()
        );
        const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

        if (diffDays > 7) {
          this.closable = false;
        }
      }
      // if (this.getChild) {
      //   this.setChild(this.getChild);
      this.fetchSubjectLevels(this.getChild.id);
      // }
      const subjects = await this.$api.subjects.getAll();
      this.setSubjects(subjects.data);
      this.imageURL = this.getAvatar;
    } catch (error) {
      console.log("error", error);
    }
  },
  methods: {
    ...mapActions("global", ["setChild", "fetchAdModules"]),
    ...mapActions("general", ["setChildProfileHash"]),
    ...mapMutations({
      setSubjects: "general/setSubjects",
      changeSubjectLevels: "childProfile/changeSubjectLevels",
      setCleverTapSession: "global/setCleverTapSession",
      setUserLastUpdatedOnClevertap: "global/setUserLastUpdatedOnClevertap",
      resetGlabalState: "global/resetState",
      resetPracticeEventsTracked: "analytics/resetPracticeEventsTracked",
      setShopCurrency: "global/setShopCurrency",
      setGameInvitation: "practiceGamesCompetition/setGameInvitation",
      changeLayout: "practiceQuestionSet/changeLayout",
      refreshLayout: "practiceQuestionSet/refreshLayout",
    }),
    async fetchCategories() {
      const categories = await this.$api.productCategories.find({
        $paginate: false,
        deleted: 0,
        $eager: "[subcategories]",
      });
      this.items = categories.map((c) => {
        return {
          text: c.name,
          icon: mdiAccountGroup,
          items: c.subcategories
            .filter((sc) => sc.deleted === 0)
            .map((subcategory) => {
              return {
                text: subcategory.name,
                url: `/shop/subcategories/${subcategory.subcategory_url}?page=1&batchSize=6&sort=price_asc&deleted=0`,
              };
            }),
        };
      });
    },
    searchProducts() {
      if (!this.searchText) return;

      const path = `/shop/products?page=1&batchSize=12&sort=price_asc&query=${this.searchText}`;

      this.$router.push(path);
    },
    showCart() {
      this.$router.push("/shop/cart");
    },
    goHome() {
      this.$router.replace("/shop");
    },
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },
    convertPoints(points) {
      const res = convertLeaderboardPoints(points);
      return res;
    },
    async acceptInvitation() {
      this.setGameInvitation(false);
      this.changeLayout("practice-questionset");
      if (this.$route.path !== "/parents/practice/opponent") {
        await this.$router.replace("/parents/practice/opponent");
      }
      this.$multiplayerSocket.acceptGameInvite();
    },
    rejectInvitation() {
      this.setGameInvitation(false);
      this.$multiplayerSocket.rejectGameInvite();
    },
    goToPage(link) {
      // check child is required to be present
      if (!!link.isChild && !this.getChilds) {
        this.childProfileDialog = true;
      } else {
        if (this.$route.path !== link.url) {
          this.$router.replace(link.url);
        }
      }
    },
    async onItemClick(link) {
      if (link === "logout") {
        this.$chatSocket.onLogout();
        localStorage.removeItem("vuex");
        this.resetPracticeEventsTracked();
        await this.$auth.logout();
        this.resetGlabalState();
        if (this.userType === "Normal") {
          this.$router.replace("/");
        } else if (this.userType === "Paytm-Mini-User") {
          this.$router.replace("/paytmlanding");
        }
      } else {
        if (this.$route.path !== link) {
          this.$router.replace(link);
        }
      }
    },
    goToChildProfile(child) {
      this.safeSetChild(child);
      //this.setChild(child);
      this.fetchSubjectLevels(child.id);

      this.$chatSocket.onSwitchChild(child.id);
      this.$multiplayerSocket.switchChild(child.id);
      this.onItemClick("/parents/child-profile");
    },

    logout() {
      this.$auth.logout();
      this.$router.replace("/");
    },
    async fetchSubjectLevels(id) {
      if (id) {
        const resp = await this.$api.userSubjectLevel.find({
          user_profile_id: id,
        });
        let subjectLevels = {};
        await resp.forEach((obj) => {
          subjectLevels[obj.subject_id] = {
            id: obj.level_id,
            level: obj.level,
          };
        });
        this.changeSubjectLevels(subjectLevels);
      }
    },
  },
  computed: {
    ...mapState("cart", ["cartItemsCount"]),
    ...mapGetters("general", ["getAppProgressbarStatus", "getSubjects"]),
    ...mapGetters("global", ["getClevertapSession"]),
    ...mapState("global", [
      "selectedUserProfile",
      "userType",
      "userLastUpdatedOnClevertap",
    ]),
    ...mapGetters("global", ["getChild", "getAvatar"]),
    ...mapGetters({
      chats: "chat/getChats",
    }),
    ...mapGetters({
      isInvitationDialog: "practiceGamesCompetition/getGameInvitation",
      getCurrentGamePhase: "practiceGamesCompetition/getCurrentGamePhase",
      getOpponent: "practiceGamesCompetition/getOpponent",
      getSubject: "practiceGamesCompetition/getSubject",
      getSummary: "practiceGamesCompetition/getSummary",
    }),
    unreadChats() {
      const chats = this.chats.filter((c) => c.unreadMsgCount);
      return chats.length;
    },
    getDropDownOptions() {
      return [{ title: "Logout", link: "logout" }];
    },
    screen() {
      if (this.$vuetify.breakpoint.xs) {
        const areas = `"header header" "sidenav main"`;
        const width = "0px 1fr";

        return { areas, width };
      }
      const areas = this.isSidebarOpen
        ? `"header header" "sidenav main"`
        : `"header header" "sidenav main"`;

      const width = this.isSidebarOpen ? "0px 1fr" : "0px 1fr";

      return { areas, width };
    },
    userName() {
      if (this.$auth && this.$auth.user) {
        const name = this.$auth.user.parent_name;

        return name
          .split(" ")
          .map((l) => l.charAt(0))
          .join("");
      }
      return "";
    },
    multiplayerPlayAgainInvite() {
      return (
        this.isInvitationDialog &&
        this.getCurrentGamePhase === "showSummary" &&
        typeof this.getSummary[this.getOpponent.id] !== "undefined"
      );
    },
    multiplayerSubjectName() {
      if (!this.getSubject?.id) return "??";
      return this.getSubjects.find((o) => o.id === this.getSubject.id)?.name;
    },
  },
  async created() {
    if (this.$route && this.$route.path) {
      let menu = this.items.find((i) => i.url == this.$route.path);
      this.selectedItem = this.items.indexOf(menu);
    }

    const childId = this.$route.query.child_id;
    if (childId) {
      const child = this.getChilds.find((c) => c.id === parseInt(childId));
      if (child) {
        this.safeSetChild(child);
        this.fetchSubjectLevels(child.id);
      }
    }

    const shopCountries = await this.$api.shop.find({
      items: "shopCountries",
    });
    const childCountry = shopCountries.find(
      (el) => el.id === this.$auth.user.country_id
    );
    let currency;
    if (childCountry) currency = childCountry;
    else currency = shopCountries.find((el) => el.id === 841);
    this.setShopCurrency(currency);
  },
  async fetch() {
    this.cart = await this.getCart();
    console.log(this.cart);
    this.showMyOrdersButton =
      this.$auth.loggedIn && this.$auth.user.role === "Parent";
  },
  async beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      vm.cart = await vm.getCart();
    }); // Call next() to proceed with entering the route
  },
};

const debounce = (fn, interval = 1000) => {
  let timeout = null;
  return (...args) => {
    if (timeout) clearTimeout(timeout);

    timeout = setTimeout(() => {
      return fn(...args);
    }, interval);
  };
};
