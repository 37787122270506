
import { mapMutations, mapState } from "vuex";

export default {
  name: "RoleSwitcher",
  computed: {
    ...mapState("global", ["current_role"]),
  },
  methods: {
    ...mapMutations("global", ["setCurrentRole"]),
    changeRole: function (newRole) {
      this.setCurrentRole(newRole);
      if (newRole === "Admin") this.$router.replace("/admin/users");

      if (newRole === "User") this.$router.replace("/shop");
    },
  },
};
